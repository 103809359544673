<template>
	<NuxtLink to="/#shop" no-prefetch>
		<div
			class="flex w-fit items-center justify-around gap-1.5 rounded-full border-none bg-black p-[10px_20px] shadow-md"
		>
			<IconsShoppingBag class="mt-[-3px] h-[24px] w-[24px] fill-primary" />
			<p class="text-center text-[0.875rem] font-normal tracking-[2px] text-white">
				ORDER NOW
			</p>
		</div>
	</NuxtLink>
</template>
